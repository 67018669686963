import React, { useState, useEffect } from 'react';
import './App.css';

// TODO Make this not hardcoded
const API_URL = 'https://api.diagram2infra.com'; //'https://mglo44txff.execute-api.us-east-1.amazonaws.com/prod';


function App() {
  const [selectedFile, setSelectedFile] = useState();
  const [password, setPassword] = useState('');
  
  const [healthCheck, setHealthCheck] = useState(null);
  
  const handleHealthCheck = async () => {
    try {
      const response = await fetch(API_URL + '/healthcheck', {
        headers: {
          'password': password,
        },
      });
      const data = await response.text();
      setHealthCheck({ status: response.status, body: data });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Load password from local storage when component is mounted
  useEffect(() => {
    const savedPassword = localStorage.getItem('password');
    if (savedPassword) {
      setPassword(savedPassword);
    }
  }, []);

  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleInputChange = (event) => {
    setPassword(event.target.value);
  };

  const handleFileSubmit = async () => {
    if (!selectedFile) {
      alert('Please select a file first.');
      return;
    }

    if (!selectedFile.name.endsWith('.drawio')) {
      alert('Please select a .drawio file.');
      return;
    }

    if (!password) {
      alert('Please enter the password first.');
      return;
    }
    
    // Save password to local storage
    localStorage.setItem('password', password);
    try {
      const response = await fetch(API_URL + '/upload-file', {
        method: 'POST',
        headers: {
          'password': password,
        },
        body: selectedFile,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const base64Data = await response.text();
      const decodedData = atob(base64Data);  // Decode the base64 data
      const uint8Array = new Uint8Array(decodedData.length);  // Create a new Uint8Array
    
      // Convert the decoded data to a Uint8Array
      for (let i = 0; i < decodedData.length; i++) {
        uint8Array[i] = decodedData.charCodeAt(i);
      }
  
      const blob = new Blob([uint8Array], { type: 'application/zip' });
      //const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'output.zip';
      link.click();
      // const data = await response.text();
      // const blob = new Blob([data], { type: 'text/plain' });
      // const url = URL.createObjectURL(blob);
      // const link = document.createElement('a');
      // link.href = url;
      // link.download = 'output.tf';
      // link.click();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <input type="text" value={password} onChange={handleInputChange} placeholder="Enter the password" />
        <input type="file" accept='.drawio' onChange={handleFileUpload} />
        <button onClick={handleFileSubmit}>Submit</button>
        <button onClick={handleHealthCheck}>Health Check</button>
        {healthCheck && (
          <div>
            <p>Status: {healthCheck.status}</p>
            <p>Body: {healthCheck.body}</p>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;